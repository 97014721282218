import React from 'react'
import { Heading } from 'rebass'
import Hero from '../components/base/Hero'

const NotFoundPage = () => (
  <Hero>
    <Heading as="h1" variant="hero.heading">Not found</Heading>
    <Heading as="h2" variant="hero.subheading">The page you were looking for does not exist.</Heading>
  </Hero>
)

export default NotFoundPage
